import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';

const Scoreboard = ({ game }) => {
  const [open, setOpen] = useState(false);
  const [selectedEnd, setSelectedEnd] = useState(0);
  const [teamAScore, setTeamAScore] = useState(0);
  const [teamBScore, setTeamBScore] = useState(0);

  const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const currentScores = currentSet.scores;

  const teamA = game.teams.teamA;
  const teamB = game.teams.teamB;

  const createScoreboardArray = (set) => {
    const scoreboard = [];
    const setScores = set.scores;

    for (let i = 0; i < setScores.length; i++) {
      const teamAScore = setScores[i].teamScores[teamA.id] ?? 0;
      const teamBScore = setScores[i].teamScores[teamB.id] ?? 0;

      //teamA total and teamB total is acummulative
      const teamATotal = setScores
        .slice(0, i + 1)
        .reduce((acc, score) => acc + (score.teamScores[teamA.id] ?? 0), 0);
      const teamBTotal = setScores
        .slice(0, i + 1)
        .reduce((acc, score) => acc + (score.teamScores[teamB.id] ?? 0), 0);
      const End = i + 1;
      scoreboard.push({ teamAScore, teamATotal, teamBScore, teamBTotal, End });
    }
    scoreboard.sort((a, b) => b.End - a.End);
    return scoreboard;
  };

  const createPlayoffScoreboard = (playoff) => {
    // playoff ends logic
    // ends: [ { game_end : 1, winningTeam: "" }  ]

    const scoreboard = [];
    const playoffEnds = playoff.ends;

    for (let i = 0; i < playoffEnds.length; i++) {
      //This data does not contain scores it contains ends and the winners of each end, one point gets allogated to each winner.
      const teamAScore = playoffEnds[i].winningTeam === teamA.id ? 1 : 0;
      const teamBScore = playoffEnds[i].winningTeam === teamB.id ? 1 : 0;

      //teamA total and teamB total is acummulative
      const teamATotal = playoffEnds
        .slice(0, i + 1)
        .reduce((acc, score) => acc + (score.winningTeam === teamA.id ? 1 : 0), 0);
      const teamBTotal = playoffEnds
        .slice(0, i + 1)
        .reduce((acc, score) => acc + (score.winningTeam === teamB.id ? 1 : 0), 0);

      const End = i + 1;

      scoreboard.push({ teamAScore, teamATotal, teamBScore, teamBTotal, End });
    }

    scoreboard.sort((a, b) => b.End - a.End);
    return scoreboard;
  };

  const handleEndEdit = (end) => {
    setSelectedEnd(end);
    const currentEnd = currentScores.find((score) => score.game_end === end);
    setTeamAScore(currentEnd.teamScores[teamA.id] ?? 0);
    setTeamBScore(currentEnd.teamScores[teamB.id] ?? 0);
    setOpen(true);
  };

  const handleEndSave = () => {
    console.log('Save End');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const row = (teamAScore, teamATotal, teamBSocre, teamBTotal, End, playoff) => {
    return (
      <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
        <Typography variant="caption">{teamAScore}</Typography>
        <Typography variant="caption">{teamATotal}</Typography>
        <Typography
          sx={{ cursor: 'pointer' }}
          variant="caption"
          color={playoff ? 'warning.main' : 'primary'}
          fontWeight={'bold'}
          onClick={() => handleEndEdit(End)}>
          {End}
        </Typography>
        <Typography variant="caption">{teamBSocre}</Typography>
        <Typography variant="caption">{teamBTotal}</Typography>
      </Box>
    );
  };

  const endsScoreboard = () => {
    return (
      <>
        {game.sets.map((set) => (
          <Paper sx={{ p: 2, borderRadius: 5, my: 2 }}>
            {game.sets.length > 0 && !set.totalSetScore && (
              <Typography variant="h5" textAlign={'center'}>
                Set {set.setNumber}
              </Typography>
            )}

            {set.totalSetScore && (
              <>
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                  <Typography variant="body1">Total</Typography>
                  <Typography variant="body1" color={'info.main'} fontWeight={'bold'}>
                    Set {set.setNumber}
                  </Typography>
                  <Typography variant="body1">Total</Typography>
                </Box>

                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                  <Typography variant="body1" color={'info.main'} fontSize={'1.5em'}>
                    {set.totalSetScore[teamA.id] ?? 0}
                  </Typography>
                  <Typography variant="body1">{set.finalScoreOnly ? 'Final Score' : ''}</Typography>
                  <Typography variant="body1" color={'info.main'} fontSize={'1.5em'}>
                    {set.totalSetScore[teamB.id] ?? 0}
                  </Typography>
                </Box>
              </>
            )}

            <Divider color={'info.main'} sx={{ my: 2 }} />

            {!set.finalScoreOnly ? (
              <>
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                  <Typography variant="body1">Score</Typography>
                  <Typography variant="body1">Total</Typography>
                  <Typography variant="body1" color={'primary'} fontWeight={'bold'}>
                    End
                  </Typography>
                  <Typography variant="body1">Score</Typography>
                  <Typography variant="body1">Total</Typography>
                </Box>

                {createScoreboardArray(set).map((item, index) => (
                  <Box key={index}>
                    {row(
                      item.teamAScore,
                      item.teamATotal,
                      item.teamBScore,
                      item.teamBTotal,
                      item.End
                    )}
                  </Box>
                ))}
              </>
            ) : null}
          </Paper>
        ))}
      </>
    );
  };

  const finalTotals = () => {
    let teamATotal = 0;
    let teamBTotal = 0;

    game.sets.map((set) => {
      if (set.totalSetScore) {
        teamATotal += set.totalSetScore[teamA.id] || 0;
        teamBTotal += set.totalSetScore[teamB.id] || 0;
      }
    });

    return (
      <>
        <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
          <Typography variant="body1">Total</Typography>
          <Typography variant="body1" color={'success.main'} fontWeight={'bold'}>
            Game Final Total
          </Typography>
          <Typography variant="body1">Total</Typography>
        </Box>

        <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
          <Typography variant="body1" color={'success.main'} fontSize={'1.5em'}>
            {teamATotal || 0} ({game.teamTotals[teamA.id]})
          </Typography>
          <Typography variant="body1">{'Final Score'}</Typography>
          <Typography variant="body1" color={'success.main'} fontSize={'1.5em'}>
            {teamBTotal || 0} ({game.teamTotals[teamB.id]})
          </Typography>
        </Box>
      </>
    );
  };

  // const setTotalScores = (set) => {
  //   const teamATotalSetScore = set.totalSetScore[teamA.id] ?? 0;
  //   const teamBTotalSetScore = set.totalSetScore[teamB.id] ?? 0;
  //   return { teamAScore, teamBScore };
  // };

  return (
    <>
      <Box display={'flex'} mt={3} flexDirection={'column'} alignItems={'center'} width="100%">
        <Typography variant="h4">Scorecard</Typography>
      </Box>

      {finalTotals()}

      {game.playoff && (
        <Paper sx={{ m: 2, p: 2, borderRadius: 5 }}>
          <Typography variant="h5" textAlign={'center'}>
            Playoff
          </Typography>

          <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
            <Typography variant="h6">Score</Typography>
            <Typography variant="h6">Total</Typography>
            <Typography variant="h6" color={'warning.main'} fontWeight={'bold'}>
              End
            </Typography>
            <Typography variant="h6">Score</Typography>
            <Typography variant="h6">Total</Typography>
          </Box>
          {createPlayoffScoreboard(game.playoff).map((item, index) => (
            <Box key={index}>
              {row(
                item.teamAScore,
                item.teamATotal,
                item.teamBScore,
                item.teamBTotal,
                item.End,
                true
              )}
            </Box>
          ))}
        </Paper>
      )}

      {endsScoreboard()}

      {selectedEnd > 0 && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit End {selectedEnd}</DialogTitle>
          <DialogContent>
            <DialogContentText>Team A</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="teamA"
              label="Team A Score"
              type="number"
              fullWidth
              value={teamAScore}
              onChange={(e) => setTeamAScore(e.target.value)}
            />
            <DialogContentText>Team B</DialogContentText>
            <TextField
              margin="dense"
              id="teamB"
              label="Team B Score"
              type="number"
              fullWidth
              value={teamBScore}
              onChange={(e) => setTeamBScore(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleEndSave}>Save</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Scoreboard;
