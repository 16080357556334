import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import leaderboardService from './leaderboardService';

export const getEventSectionsLeaderboard = createAsyncThunk(
  'leaderboard/getEventSectionsLeaderboard',
  async (data, thunkAPI) => {
    return await leaderboardService
      .getEventSectionsLeaderboard(data.eventId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getEventLeaderboard = createAsyncThunk(
  'leaderboard/getEventLeaderboard',
  async (data, thunkAPI) => {
    return await leaderboardService
      .getEventLeaderboard(data.eventId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getEventScorecard = createAsyncThunk(
  'leaderboard/getEventScorecard',
  async (data, thunkAPI) => {
    return await leaderboardService
      .getEventScorecard(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState: {
    sectionLeaderboard: []
  },
  reducers: {
    setSectionLeaderboard: (state, action) => {
      state.sectionLeaderboard = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventSectionsLeaderboard.fulfilled, (state, action) => {
        state.sectionLeaderboard = action.payload;
        state.loading = false;
      })
      .addCase(getEventSectionsLeaderboard.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getEventSectionsLeaderboard.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEventLeaderboard.fulfilled, (state, action) => {
        state.leaderboard = action.payload;
        state.loading = false;
      })
      .addCase(getEventLeaderboard.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getEventLeaderboard.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEventScorecard.fulfilled, (state, action) => {
        state.scorecard = action.payload;
        state.loadingScorecard = false;
      })
      .addCase(getEventScorecard.rejected, (state, action) => {
        state.loadingScorecard = false;
      })
      .addCase(getEventScorecard.pending, (state, action) => {
        state.loadingScorecard = true;
      });
  }
});

export const { setSectionLeaderboard } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
