import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { setLoadingEvent, setEvent, getAllEventsByVenue } from '../../features/event/eventSlice';

import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { useColorMode } from '../../hooks/colorModeProvider';
import EmbeddedDetailedSectionsView from './sectionsDetailed';
import EmbeddedDetailedLogsView from './logsDetailed';

const EmbeddedScores = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event.event);
  const { mode, setMode } = useColorMode();

  useEffect(() => {
    setMode('light');
  }, [mode, setMode]);

  //Get Event ID from params.
  const { eventId } = useParams();

  //Get event by eventId
  useEffect(() => {
    if (!eventId) return;

    dispatch(setLoadingEvent(true));
    const docRef = doc(store, 'events', eventId);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const eventData = { id: docSnap.id, ...docSnap.data() };
        dispatch(setEvent(eventData));
      } else {
        console.log('No such document!');
      }
      dispatch(setLoadingEvent(false));
    });

    return () => unsubscribe();
  }, [eventId, dispatch]);

  return event && event.sections ? <EmbeddedDetailedSectionsView /> : <EmbeddedDetailedLogsView />;
};

export default EmbeddedScores;
