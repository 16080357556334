import axiosInstance from '../axiosInstance';

const getEventSectionsLeaderboard = async (eventId) => {
  return axiosInstance.get(`/leaderboard/event/${eventId}/sectionleaderboard`).then((response) => {
    return response.data;
  });
};

const getEventLeaderboard = async (eventId) => {
  return axiosInstance.get(`/leaderboard/event/${eventId}/leaderboard`).then((response) => {
    return response.data;
  });
};

const getEventScorecard = async (eventId) => {
  return axiosInstance.get(`/leaderboard/event/${eventId}/scorecard`).then((response) => {
    return response.data;
  });
};

const leaderboardService = {
  getEventSectionsLeaderboard,
  getEventLeaderboard,
  getEventScorecard
};

export default leaderboardService;
