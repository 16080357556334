import { setDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../axiosInstance';

const get = async () => {
  return axiosInstance.get('/event').then((response) => {
    return response.data;
  });
};

const getAllByVenue = async (venueId) => {
  return axiosInstance.get(`/event/venue/${venueId}`).then((response) => {
    return response.data;
  });
};

const getById = async (eventId) => {
  return axiosInstance.get(`/event/${eventId}`).then((response) => {
    return response.data;
  });
};

const create = async (event) => {
  return axiosInstance.post('/event', event).then((response) => {
    return response.data;
  });
};

const update = async (eventId, event) => {
  return axiosInstance.put(`/event/${eventId}`, event).then((response) => {
    return response.data;
  });
};

const remove = async (eventId) => {
  return axiosInstance.delete(`/event/${eventId}`).then((response) => {
    return response.data;
  });
};

const updateTeam = async (eventId, team) => {
  return axiosInstance.put(`/event/${eventId}/team`, { team }).then((response) => {
    return response.data;
  });
};

const addSection = async (eventID, section) => {
  // id: uuid, name:String, players:[array], games:[array], rounds:Number,
  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();
  const generatedSectionId = uuidv4();

  section.id = generatedSectionId;

  if (!Array.isArray(event.sections)) {
    event.sections = [];
  }
  event.sections.push(section);

  await setDoc(docRef, event);
};

const addPlayer = async (eventID, player) => {
  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();

  const generatedPlayerId = uuidv4();
  player.id = generatedPlayerId;

  if (!Array.isArray(event.players)) {
    event.players = [];
  }
  event.players.push(player);
  await setDoc(docRef, event);
};

const updatePlayer = async (eventID, player) => {
  console.log('player', player);

  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();

  const playerIndex = event.players.findIndex((p) => p.id === player.id);
  event.players[playerIndex] = player;

  await setDoc(docRef, event);
};

const removePlayer = async (eventID, playerID) => {
  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();

  const playerIndex = event.players.findIndex((p) => p.id === playerID);
  event.players.splice(playerIndex, 1);

  const section = event.sections.find((section) => section.players.find((p) => p.id === playerID));

  const sectionPlayerIndex = section.players.findIndex((p) => p.id === playerID);

  section.players.splice(sectionPlayerIndex, 1);

  await setDoc(docRef, event);
};

const addTeam = async (eventID, team) => {
  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();

  const generatedTeamId = uuidv4();
  team.id = generatedTeamId;

  if (!Array.isArray(event.teams)) {
    event.teams = [];
  }
  event.teams.push(team);
  await setDoc(docRef, event);
};

// const updateTeam = async (eventID, team) => {
//   const docRef = doc(store, 'events', eventID);
//   const docSnap = await getDoc(docRef);
//   const event = docSnap.data();

//   const teamIndex = event.teams.findIndex((t) => t.id === team.id);
//   event.teams[teamIndex] = team;

//   await setDoc(docRef, event);
// };

const removeTeam = async (eventID, teamID) => {
  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();

  const teamIndex = event.teams.findIndex((p) => p.id === teamID);
  event.teams.splice(teamIndex, 1);

  const section = event.sections.find((section) => section.teams.find((t) => t.id === teamID));

  const sectionTeamIndex = section.teams.findIndex((t) => t.id === teamID);
  section.teams.splice(sectionTeamIndex, 1);
  await setDoc(docRef, event);
};

// const addGame = async (eventID, sectionID, game) => {
//   try {
//     console.log({ eventID, game });
//     const docRef = doc(store, 'events', eventID);
//     const docSnap = await getDoc(docRef);
//     const event = docSnap.data();

//     const section = event.sections.find((section) => section.id === sectionID);

//     if (!Array.isArray(section.games)) {
//       section.games = [];
//     }

//     section.games.push(game);

//     await setDoc(docRef, event);

//     return game;
//   } catch (err) {
//     throw err;
//   }
// };

const updateGame = async (eventID, game) => {
  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();

  const section = event.sections.find((section) => section.id === game.section);

  const gameIndex = section.games.findIndex((g) => g.id === game.id);
  section.games[gameIndex] = game;

  await setDoc(docRef, event);
};

const deleteGame = async (eventID, gameID) => {
  const docRef = doc(store, 'events', eventID);
  const docSnap = await getDoc(docRef);
  const event = docSnap.data();

  const section = event.sections.find((section) => section.games.find((g) => g.id === gameID));

  const gameIndex = section.games.findIndex((g) => g.id === gameID);
  section.games.splice(gameIndex, 1);

  await setDoc(docRef, event);
};

const getCMEvents = async (cmClubId) => {
  return axiosInstance
    .get(`https://api.club-master.co.za/clubs/${cmClubId}/events`)
    .then((response) => {
      return response.data;
    });
};

const getCMEvent = async (cmEventId) => {
  return axiosInstance.get(`https://api.club-master.co.za/events/${cmEventId}`).then((response) => {
    return response.data;
  });
};

const updateCMEvent = async (cmEventId, bsEventId) => {
  return axiosInstance
    .put(`https://api.club-master.co.za/events/${cmEventId}`, { bowlsScoutId: bsEventId })
    .then((response) => {
      return response.data;
    });
};

const eventService = {
  get,
  getById,
  getAllByVenue,
  create,
  update,
  remove,
  updateTeam,
  addSection,
  addPlayer,
  updatePlayer,
  removePlayer,
  addTeam,
  //updateTeam,
  removeTeam,
  // addGame,
  updateGame,
  deleteGame,
  getCMEvents,
  getCMEvent,
  updateCMEvent
};

export default eventService;
