import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Box,
  Typography,
  TextField,
  useMediaQuery,
  Stack
} from '@mui/material';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import { inputSetScore } from '../../features/game/gameSlice';
import TeamNameHeaders from '../ui/playerDisplay/teamNameHeaders';

import { useTheme } from '@mui/material/styles';

// const schema = yup.object().shape({
//   teamATotalScore: yup.number().required('Team 1 Score is required'),
//   teamBTotalScore: yup.number().required('Team 2 Score is required'),
//   selectedSet: yup.number().required('Sets is required').default(1)
// });

const SetInput = ({ game, set, onChange }) => {
  const theme = useTheme();
  const teamA = game.teams.teamA;
  const teamB = game.teams.teamB;

  const [teamScore, setTeamScore] = useState({
    [teamA.id]: null,
    [teamB.id]: null
  });

  useEffect(() => {
    onChange(teamScore);
  }, [teamScore, onChange]);

  const handleUpdateScore = (team, value) => {
    const numericValue = value !== '' ? parseInt(value, 10) : null;

    setTeamScore((prevScores) => ({
      ...prevScores,
      [team.id]: numericValue
    }));
  };

  return (
    <Stack>
      <Typography color={'blue.main'} fontWeight={'bold'} alignSelf={'center'}>
        SET {set}
      </Typography>
      <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'} m={1}>
        <Paper
          sx={{
            width: '140px',
            height: '140px',
            alignSelf: 'center',
            borderRadius: 2,
            p: 1,
            backgroundColor: 'background.main'
          }}>
          <TeamNameHeaders team={teamA}>
            <TextField
              variant="standard"
              fullWidth
              sx={{
                height: '100px',
                mt: 2,
                '& .MuiInputBase-input': {
                  textAlign: 'center'
                },
                '& .MuiInput-underline': {
                  '&:before': {
                    borderBottomWidth: '2px',
                    borderRadius: '10px'
                  },
                  '&:after': {
                    borderBottomWidth: '3px',
                    borderRadius: '10px'
                  }
                }
              }}
              InputProps={{
                style: {
                  fontSize: '1.5rem',
                  backgroundColor: theme.palette.background.paper,
                  padding: '5px',
                  borderRadius: '10px'
                }
              }}
              placeholder="0"
              onChange={(event) => handleUpdateScore(teamA, event.target.value)}
            />
          </TeamNameHeaders>
        </Paper>

        <Paper
          sx={{
            width: '140px',
            height: '140px',
            alignSelf: 'center',
            borderRadius: 2,
            p: 1,
            backgroundColor: 'background.main'
          }}>
          <TeamNameHeaders team={teamB}>
            <TextField
              variant="standard"
              fullWidth
              sx={{
                height: '100px',
                mt: 2,
                '& .MuiInputBase-input': {
                  textAlign: 'center'
                },
                '& .MuiInput-underline': {
                  '&:before': {
                    // Unfocused underline thickness
                    borderBottomWidth: '2px', // Change thickness
                    borderRadius: '10px'
                  },
                  '&:after': {
                    // Focused underline thickness
                    borderBottomWidth: '3px', // Change thickness when focused
                    borderRadius: '10px'
                  }
                }
              }}
              InputProps={{
                style: {
                  fontSize: '1.5rem',
                  backgroundColor: theme.palette.background.paper,
                  padding: '5px',
                  borderRadius: '10px'
                }
              }}
              placeholder="0"
              onChange={(event) => handleUpdateScore(teamB, event.target.value)}
            />
          </TeamNameHeaders>
        </Paper>
      </Box>
    </Stack>
  );
};

const InputScoreDialog = ({ game, onOpen }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [scores, setScores] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const totalSets = game.gameFormat.sets;

  // const teamA = game.teams.teamA;
  // const teamB = game.teams.teamB;

  // const {
  //   register,
  //   control,
  //   handleSubmit,
  //   reset,
  //   formState: { errors }
  // } = useForm({
  //   resolver: yupResolver(schema)
  // });

  // useEffect(() => {
  //   return () => {
  //     reset();
  //   };
  // }, [reset]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateScores = (scores) => {
    if (Object.keys(scores).length !== totalSets) {
      return false;
    }

    const teamAId = game.teams.teamA.id;
    const teamBId = game.teams.teamB.id;

    for (const setId in scores) {
      const setScores = scores[setId].teams;
      if (
        setScores[teamAId] === null ||
        setScores[teamAId] === '' ||
        setScores[teamBId] === null ||
        setScores[teamBId] === ''
      ) {
        return false;
      }
    }

    return true;
  };

  const submit = () => {
    let data = {};
    data.game = game;
    data.gameId = game.id;
    data.scores = scores;

    if (validateScores(scores, game.gameFormat.sets)) {
      dispatch(inputSetScore(data))
        .unwrap()
        .then(() => {
          setOpen(false);
          onOpen();
        });
    }
  };

  const handleScoreChange = (teams, set) => {
    const existingScore = scores.find((s) => s.set === set);

    if (!existingScore) {
      scores.push({ set, teams });
    } else {
      existingScore.teams = teams;
    }

    setScores(scores);
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => handleClickOpen()}>
        Input Score
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'} fullScreen={isMobile}>
        <DialogTitle>Score Game - {game.name ?? 'Private Game'}</DialogTitle>
        <DialogContent>
          {totalSets > 1 &&
            Array.from({ length: game.gameFormat.sets }, (_, index) => (
              <SetInput
                game={game}
                set={index + 1}
                onChange={(teams) => handleScoreChange(teams, index + 1)}
              />
            ))}

          {/* <Select control={control} label="Sets" name="selectedSet">
               {Array.from({ length: game.gameFormat.sets }, (_, index) => (
                <MenuItem key={index} value={index + 1}>
                  Set {index + 1}
                </MenuItem>
              ))}
            </Select> */}

          {/* <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
            <Paper
              sx={{
                width: '140px',
                height: '140px',
                alignSelf: 'center',
                borderRadius: 2,
                p: 1,
                backgroundColor: 'background.main'
              }}>
              <TeamNameHeaders team={teamA}>
                <TextField
                  variant="standard"
                  fullWidth
                  defaultValue={
                    currentSet.totalSetScores ? currentSet.totalSetScores[teamA.id] : null
                  }
                  error={!!errors.teamATotalScore}
                  sx={{
                    height: '100px',
                    mt: 2,
                    '& .MuiInputBase-input': {
                      textAlign: 'center'
                    },
                    '& .MuiInput-underline': {
                      '&:before': {
                        borderBottomWidth: '2px',
                        borderRadius: '10px'
                      },
                      '&:after': {
                        borderBottomWidth: '3px',
                        borderRadius: '10px'
                      }
                    }
                  }}
                  InputProps={{
                    style: {
                      fontSize: '1.5rem',
                      backgroundColor: theme.palette.background.paper,
                      padding: '5px',
                      borderRadius: '10px'
                    }
                  }}
                  placeholder="0"
                  {...register('teamATotalScore')}
                />
              </TeamNameHeaders>
            </Paper>

            <Paper
              sx={{
                width: '160px',
                height: '160px',
                alignSelf: 'center',
                borderRadius: 2,
                p: 1,
                backgroundColor: 'background.main'
              }}>
              <TeamNameHeaders team={teamB}>
                <TextField
                  variant="standard"
                  fullWidth
                  defaultValue={
                    currentSet.totalSetScores ? currentSet.totalSetScores[teamB.id] : null
                  }
                  error={!!errors.teamBTotalScore}
                  sx={{
                    height: '100px',
                    mt: 2,
                    '& .MuiInputBase-input': {
                      textAlign: 'center'
                    },
                    '& .MuiInput-underline': {
                      '&:before': {
                        // Unfocused underline thickness
                        borderBottomWidth: '2px', // Change thickness
                        borderRadius: '10px'
                      },
                      '&:after': {
                        // Focused underline thickness
                        borderBottomWidth: '3px', // Change thickness when focused
                        borderRadius: '10px'
                      }
                    }
                  }}
                  InputProps={{
                    style: {
                      fontSize: '1.5rem',
                      backgroundColor: theme.palette.background.paper,
                      padding: '5px',
                      borderRadius: '10px'
                    }
                  }}
                  placeholder="0"
                  {...register('teamBTotalScore')}
                />
              </TeamNameHeaders>
            </Paper>
          </Box> */}

          {/* <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
            <Typography variant="caption" color="error">
              {errors.selectedSet?.message}
            </Typography>
            <Typography variant="caption" color="error">
              {errors.teamATotalScore?.message}
            </Typography>
            <Typography variant="caption" color="error">
              {errors.teamBTotalScore?.message}
            </Typography>
          </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          <Button variant="contained" onClick={() => submit()} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InputScoreDialog;
